import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginResponse } from '../shared/models/idp/login';
import { SharedService } from '../global/services/common/shared.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styles: [`
        .active-link {
            background-color: #e0e6ff;
            color: white;
            border-radius: 6px;
        }

        .layout-menu li {
            transition: background-color 0.3s ease;
        }

        .disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    `]
})
export class AppMenuComponent implements OnInit {
    private currentUserSubject!: BehaviorSubject<LoginResponse>;
    model: any[] = [];
    isLoading: boolean = false;

    constructor(public layoutService: LayoutService, private router: Router, private sharedService: SharedService) { }

    ngOnInit() {
        var data = localStorage.getItem('currentUser');
        if (data != null) {
            this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(data));
        }
        const UserRole = this.currentUserSubject.value?.applicationUser?.roles?.[0];

        if (UserRole?.toUpperCase() != 'STUDENT') {
            this.sharedService.isLoading$.subscribe(isLoading => {
                this.isLoading = isLoading;
            });
            this.model = [
                {
                    label: 'Dashboard', icon: 'fas fa-home', routerLink: ['dashboard'], path: 'home/dashboard'
                },
                {
                    label: 'Big Leads', icon: 'fas fa-briefcase', routerLink: ['bigleads/dashboard'], path: 'home/bigleads'
                },
                {
                    label: 'Mind Spark', icon: 'fas fa-brain', routerLink: ['mindspark/dashboard'], path: 'home/mindspark'
                },
                {
                    label: 'Knowledge Stand', icon: 'fas fa-book-open', routerLink: ['knowledgestand/dashboard'], path: 'home/knowledgestand'
                },
                {
                    label: 'Fin Pro', icon: 'fas fa-indian-rupee-sign', routerLink: ['finpro/dashboard'], path: 'home/finpro'
                },
                {
                    label: 'SmallBiz Gurus', icon: 'fas fa-handshake', routerLink: ['smallbizgurus/dashboard'], path: 'home/smallbizgurus'
                },
                {
                    label: 'Cloud Bytes', icon: 'fas fa-cloud', routerLink: ['cloudbytes/dashboard'], path: 'home/cloudbytes'
                },
                {
                    label: 'Executive Edge', icon: 'fas fa-user-tie', routerLink: ['executiveedge/dashboard'], path: 'home/executiveedge'
                },
                {
                    label: 'Others', icon: 'fas fa-align-left',
                    items: [
                        {
                            label: 'Digital Finger', icon: 'fas fa-user-gear', routerLink: ['digitalfingers/dashboard'], path: 'home/digitalfingers'
                        },
                        {
                            label: 'TimeClock Plus', icon: 'fas fa-calendar-days', routerLink: ['timeclockplus/dashboard'], path: 'home/timeclockplus'
                        },
                        {
                            label: 'Virtual Learn', icon: 'fas fa-atlas', routerLink: ['virtuallearn/dashboard'], path: 'home/virtuallearn'
                        },
                        {
                            label: 'Inventory Matrix', icon: 'fas fa-warehouse', routerLink: ['inventorymatrix/dashboard'], path: 'home/inventorymatrix'
                        },
                        {
                            label: 'FAQ', icon: 'fas fa-person-circle-question', routerLink: ['faq/dashboard'], path: 'home/faq'
                        }
                    ]
                }
            ];
        }
        // Menu For Student Role
        else {
            this.model = [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/home/students/dashboard'] },
                {
                  label: 'Accounts', icon: 'pi pi-fw pi-credit-card',
                  items: [
                    { label: 'Payment', icon: 'pi pi-fw pi-pencil', routerLink: ['/home/students/payments'] },
                    { label: 'Challan', icon: 'pi pi-fw pi pi-fw pi-globe', routerLink: ['/home/students/challan'] },
                    { label: 'Receipt', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/home/students/reciept'] },
          
                  ]
                },
                {
                  label: 'Academics', icon: 'pi pi-fw pi-book', badgeStyleClass: 'text-badge',
                  items: [
                    { label: 'Semester Registration', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/students/semester-registration'] },
                    { label: 'Attendance', icon: 'pi pi-fw pi-check-square', routerLink: ['/home/students/batch-attendence-summary'] },
                    { label: 'Curriculum Framework', icon: 'pi pi-fw pi-book', routerLink: ['/home/students/curriculum-framework'] },
                    { label: 'Time Table', icon: 'pi pi-fw pi-bookmark', routerLink: ['/home/students/time-table']}
                  ]
                },
                {
                  label: 'Examinations', icon: 'pi pi-fw pi-compass',
                  items: [
                    {
                      label: 'Registration', icon: 'pi pi-fw pi-desktop',
                      items: [
                        { label: 'Regular Examination', icon: 'pi pi-fw pi-desktop', routerLink: ['/home/students/examination-registration'] },
                      ]
                    },
                    {
                      label: 'Backlog/ Supplementary Examination', icon: 'pi pi-fw pi-desktop',
                      items: [
                        { label: 'Backlog/ Supplementary Examination Application', icon: 'pi pi-fw pi-desktop', routerLink: ['/home/students/backlog-examination-application'] },
                        { label: 'Backlog/ Supplementary Examination Registration', icon: 'pi pi-fw pi-desktop', routerLink: ['/home/students/backlog-examination-registration'] },
                      ]
                    },
                    { label: 'Result', icon: 'pi pi-fw pi-bookmark', routerLink: ['/home/students/academic-result'] },
                    { label: 'Download Admit Card', icon: 'pi pi-download', routerLink: ['/home/students/admit-card'] },
                    { label: 'Scrutiny Application', icon: 'pi pi-external-link', routerLink: ['/home/students/scrutiny-application'] },
                  ]
                },
                {
                  label: 'Services', icon: 'pi pi-fw pi-server',
                  items: [
                    {
                      label: 'Search Book', icon: 'pi pi-file-pdf', routerLink: ['/home/students/search-book']
                    },
                    {
                      label: 'Helpshift', icon: 'pi pi-fw pi-align-left',
                      items: [
                        { label: 'Payment Help', icon: 'pi pi-fw pi-palette', routerLink: ['/home/students/payment-help'] },
                        //{ label: 'ProfileUpdateRequest', icon: 'pi pi-fw pi-pencil' },
                        { label: 'Request Tracker', icon: 'pi pi-fw pi-pencil', routerLink: ['/home/students/request-tracker'] },
                        { label: 'Info Update Request', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/students/student-info-update-request'] },
                        { label: 'Service Request', icon: 'pi pi-fw pi-palette', routerLink: ['/home/students/service-request-list'] },
                      ]
                    },
                    {
                      label: 'Certificate', icon: 'pi pi-fw pi-ticket',
                      items: [
                        { label: 'Bonafide (Job)', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/students/bonafied-job'] },
                        { label: 'Bonafide (Fees)', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/students/bonafied-fees'] },
                        { label: 'Bonafide (E-Kalyan)', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/students/bonafied-e-kaliyan-scholarship'] }
                      ]
                    },
                    {
                      label: 'Document Centre', icon: 'pi pi-file-pdf', routerLink: ['/home/students/document-center']
                    },
                    {
                      label: 'Grievance Redressal Committee', icon: 'pi pi-fw pi-ticket', url: 'https://sbu.ac.in/greivanceredressal.aspx', target: "_blank"
                    },
                    { label: 'Caste Based Discrimination', icon: 'pi pi-fw pi-ticket', url: "https://sbu.ac.in/caste-based-discrimination.aspx", target: "_blank" },
                    {
                      label: 'E-Learning', icon: 'pi pi-fw pi-times-circle', routerLink: ['/home/students/e-learning']
                    },
                    {
                      label: 'Feedback', icon: 'pi pi-fw pi-comment',
                      items: [
                        { label: 'Faculty Feedback', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/students/student-feedback'] }
                      ]
                    },
                  ]
                },
          
                {
                  label: 'Settings', icon: 'pi pi-fw pi-cog',
                  items: [
                    {
                      label: 'Profile', icon: 'pi pi-fw pi-user',
                      items: [
                        { label: 'View', icon: 'pi pi-fw pi-palette', routerLink: ['/home/students/student-profile'] },
                        { label: 'Update', icon: 'pi pi-fw pi-palette', routerLink: ['/home/students/student-profile-update'] },
                      ]
                    },
                    { label: 'Change Password', icon: 'pi pi-fw pi-palette', routerLink: ['/home/students/change-password'] },
          
                  ]
                }
              ];
        }
    }

    isActive(itemPath: string): boolean {
        const currentUrl = this.router.url;
        return currentUrl.startsWith(`/${itemPath}`) || currentUrl.includes(`/${itemPath}/`);
    }
}