export const statusList = [
    { label: 'NEW', value: 'NEW' },
    { label: 'PUBLISHED', value: 'PUBLISHED' },
    { label: 'DELETED', value: 'DELETED' },
    { label: 'DRAFT', value: 'DRAFT' },
];

export const studentStatus = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Passed Out', value: 'PASSEDOUT' },
    { label: 'Dropped Out', value: 'DROPPEDOUT' },
    { label: 'Break Out', value: 'BREAKOUT' },
    { label: 'Suspended', value: 'SUSPENDED' },
];

export const versionList = [
    { label: 'v1.0', value: 'v1.0' },
    { label: 'v2.0', value: 'v2.0' },
    { label: 'v3.0', value: 'v3.0' },
    { label: 'v4.0', value: 'v4.0' },
    { label: 'v5.0', value: 'v5.0' },
    { label: 'v6.0', value: 'v6.0' },
    { label: 'v7.0', value: 'v7.0' },
    { label: 'v8.0', value: 'v8.0' },
    { label: 'v9.0', value: 'v9.0' },
    { label: 'v10.0', value: 'v10.0' }
];
export const monthList = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
];

export const employeeSearchList = [
    { label: 'Employee Code', value: 'employeeCode' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Aadhar', value: 'aadhar' },
    { label: 'PAN', value: 'pan' },
];

export const maritalStatusList = [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed ', value: 'Widowed' }
];

export const YesNo = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export const familyRelationList = [
    { label: 'Father', value: 1 },
    { label: 'Mother', value: 2 },
];

export const remarksList = [
    { label: 'Pass', value: 'Pass' },
    { label: 'Course Not Completed', value: 'Course Not Completed' },
];

export const reasonList = [
    { label: 'Course Completed', value: 'Course Completed' },
    { label: 'Personal', value: 'Personal' },
];

export const bloodGroupList = [
    { label: 'A+', value: 'A+' },
    { label: 'O+', value: 'O+' },
    { label: 'B+', value: 'B+' },
    { label: 'AB+', value: 'AB+' },
    { label: 'A-', value: 'A-' },
    { label: 'O-', value: 'O-' },
    { label: 'B-', value: 'B-' },
    { label: 'AB-', value: 'AB-' },
];

export const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' },
];
export const Religion = [
    { label: 'Hinduism', value: 'Hinduism' },
    { label: 'Islam', value: 'Islam' },
    { label: 'Christianity', value: 'Christianity' },
    { label: 'Sikhism', value: 'Sikhism' },
    { label: 'Zoroastrism', value: 'Zoroastrism' },
    { label: 'Judaism', value: 'Judaism' },
    { label: 'Jainism', value: 'Jainism' },
    { label: 'Buddhism', value: 'Buddhism' },
];

export const categoryList = [
    { label: "GENERAL", value: "GENERAL" },
    { label: 'OBC', value: 'OBC' },
    { label: "SC", value: "SC" },
    { label: "ST", value: "ST" }
]

export const sectionList = [
    {label : 'A', value : 'A'},
    {label : 'B', value : 'B'},
    {label : 'C', value : 'C'},
    {label : 'D', value : 'D'},
    {label : 'E', value : 'E'},
    {label : 'F', value : 'F'},
    {label : 'G', value : 'G'},
    {label : 'H', value : 'H'},
    {label : 'I', value : 'A'},
    {label : 'J', value : 'J'},
    {label : 'K', value : 'C'},
    {label : 'L', value : 'D'},
    {label : 'M', value : 'E'},
    {label : 'N', value : 'F'},
    {label : 'O', value : 'G'},
    {label : 'P', value : 'H'},
    {label : 'Q', value : 'A'},
    {label : 'R', value : 'B'},
    {label : 'S', value : 'C'},
    {label : 'T', value : 'D'},
    {label : 'U', value : 'E'},
    {label : 'V', value : 'F'},
    {label : 'W', value : 'G'},
    {label : 'X', value : 'H'},
    {label : 'Y', value : 'A'},
    {label : 'Z', value : 'B'},
]

export const stateList = [
    { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
    { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
    { label: 'Assam', value: 'Assam' },
    { label: 'Bihar', value: 'Bihar' },
    { label: 'Chhattisgarh', value: 'Chhattisgarh' },
    { label: 'Goa', value: 'Goa' },
    { label: 'Gujarat', value: 'Gujarat' },
    { label: 'Haryana', value: 'Haryana' },
    { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { label: 'Jharkhand', value: 'Jharkhand' },
    { label: 'Karnataka', value: 'Karnataka' },
    { label: 'Kerala', value: 'Kerala' },
    { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
    { label: 'Maharashtra', value: 'Maharashtra' },
    { label: 'Manipur', value: 'Manipur' },
    { label: 'Mizoram', value: 'Mizoram' },
    { label: 'Nagaland', value: 'Nagaland' },
    { label: 'Odisha', value: 'Odisha' },
    { label: 'Punjab', value: 'Punjab' },
    { label: 'Rajasthan', value: 'Rajasthan' },
    { label: 'Sikkim', value: 'Sikkim' },
    { label: 'Tamil Nadu', value: 'Tamil Nadu' },
    { label: 'Telangana', value: 'Telangana' },
    { label: 'Tripura', value: 'Tripura' },
    { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
    { label: 'Uttarakhand', value: 'Uttarakhand' },
    { label: 'West Bengal', value: 'West Bengal' },
]
export const titleList = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Miss.', value: 'Miss.' },
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Prof.', value: 'Prof.' },
];

export const documentTypeList = [
    { label: 'Address', value: 'Address' },
    { label: 'Bank', value: 'Bank' },
    { label: 'Educational', value: 'Educational' },
    { label: 'Professional', value: 'Professional' },
    { label: 'Project', value: 'Project' },
    { label: 'Identity', value: 'Identity' },
];

export const addressTypeList = [
    { label: 'Local', value: 'Local' },
    { label: 'Permanent', value: 'Permanent' },
];

export const accountTypeList = [
    { label: 'Current', value: 'Current' },
    { label: 'Savings', value: 'Savings' },
    { label: 'Salary', value: 'Salary' },
    { label: 'Fixed deposit', value: 'Fixed deposit' },
    { label: 'Recurring deposit', value: 'Recurring deposit' },
    { label: 'NRI', value: 'NRI' }
];

export const employmentTypeList = [
    { label: 'Full-time', value: 'Full-time' },
    { label: 'Part-time', value: 'Part-time' },
    { label: 'Contractual', value: 'Contractual' },
    { label: 'Commission-based', value: 'Commission-based' },
    { label: 'Casual', value: 'Casual' },
    { label: 'Apprenticeship', value: 'Apprenticeship' },
    { label: 'Traineeship', value: 'Traineeship' },
    { label: 'Probation', value: 'Probation' }
];

export const payrollTypeList = [
    { label: 'REGULAR', value: 'REGULAR' },
    { label: 'VOUCHER', value: 'VOUCHER' }
];

export const nationalityList = [
    { label: 'Indian', value: 'Indian' },
    { label: 'Afghan', value: 'Afghan' },
    { label: 'Albanian', value: 'Albanian' },
    { label: 'Algerian', value: 'Algerian' },
    { label: 'American', value: 'American' },
    { label: 'Andorran', value: 'Andorran' },
    { label: 'Angolan', value: 'Angolan' },
    { label: 'Antiguans', value: 'Antiguans' },
    { label: 'Argentinean', value: 'Argentinean' },
    { label: 'Armenian', value: 'Armenian' },
    { label: 'Australian', value: 'Australian' },
    { label: 'Austrian', value: 'Austrian' },
    { label: 'Azerbaijani', value: 'Azerbaijani' },
    { label: 'Bahamian', value: 'Bahamian' },
    { label: 'Bahraini', value: 'Bahraini' },
    { label: 'Bangladeshi', value: 'Bangladeshi' },
    { label: 'Barbadian', value: 'Barbadian' },
    { label: 'Barbudans', value: 'Barbudans' },
    { label: 'Batswana', value: 'Batswana' },
    { label: 'Belarusian', value: 'Belarusian' },
    { label: 'Belgian', value: 'Belgian' },
    { label: 'Belizean', value: 'Belizean' },
    { label: 'Beninese', value: 'Beninese' },
    { label: 'Bhutanese', value: 'Bhutanese' },
    { label: 'Bolivian', value: 'Bolivian' },
    { label: 'Bosnian', value: 'Bosnian' },
    { label: 'Brazilian', value: 'Brazilian' },
    { label: 'British', value: 'British' },
    { label: 'Bruneian', value: 'Bruneian' },
    { label: 'Bulgarian', value: 'Bulgarian' },
    { label: 'Burkinabe', value: 'Burkinabe' },
    { label: 'Burmese', value: 'Burmese' },
    { label: 'Burundian', value: 'Burundian' },
    { label: 'Cambodian', value: 'Cambodian' },
    { label: 'Cameroonian', value: 'Cameroonian' },
    { label: 'Canadian', value: 'Canadian' },
    { label: 'Cape Verdean', value: 'Cape Verdean' },
    { label: 'Central African', value: 'Central African' },
    { label: 'Chadian', value: 'Chadian' },
    { label: 'Chilean', value: 'Chilean' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Colombian', value: 'Colombian' },
    { label: 'Comoran', value: 'Comoran' },
    { label: 'Congolese', value: 'Congolese' },
    { label: 'Costa Rican', value: 'Costa Rican' },
    { label: 'Croatian', value: 'Croatian' },
    { label: 'Cuban', value: 'Cuban' },
    { label: 'Cypriot', value: 'Cypriot' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Djibouti', value: 'Djibouti' },
    { label: 'Dominican', value: 'Dominican' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'East Timorese', value: 'East Timorese' },
    { label: 'Ecuadorean', value: 'Ecuadorean' },
    { label: 'Egyptian', value: 'Egyptian' },
    { label: 'Emirian', value: 'Emirian' },
    { label: 'Equatorial Guinean', value: 'Equatorial Guinean' },
    { label: 'Eritrean', value: 'Eritrean' },
    { label: 'Estonian', value: 'Estonian' },
    { label: 'Ethiopian', value: 'Ethiopian' },
    { label: 'Fijian', value: 'Fijian' },
    { label: 'Filipino', value: 'Filipino' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'French', value: 'French' },
    { label: 'Gabonese', value: 'Gabonese' },
    { label: 'Gambian', value: 'Gambian' },
    { label: 'Georgian', value: 'Georgian' },
    { label: 'German', value: 'German' },
    { label: 'Ghanaian', value: 'Ghanaian' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Grenadian', value: 'Grenadian' },
    { label: 'Guatemalan', value: 'Guatemalan' },
    { label: 'Guinea-Bissauan', value: 'Guinea-Bissauan' },
    { label: 'Guinean', value: 'Guinean' },
    { label: 'Guyanese', value: 'Guyanese' },
    { label: 'Haitian', value: 'Haitian' },
    { label: 'Herzegovinian', value: 'Herzegovinian' },
    { label: 'Honduran', value: 'Honduran' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'I-Kiribati', value: 'I-Kiribati' },
    { label: 'Icelander', value: 'Icelander' },
    { label: 'Indian', value: 'Indian' },
    { label: 'Indonesian', value: 'Indonesian' },
    { label: 'Iranian', value: 'Iranian' },
    { label: 'Iraqi', value: 'Iraqi' },
    { label: 'Irish', value: 'Irish' },
    { label: 'Israeli', value: 'Israeli' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Ivorian', value: 'Ivorian' },
    { label: 'Jamaican', value: 'Jamaican' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Jordanian', value: 'Jordanian' },
    { label: 'Kazakhstani', value: 'Kazakhstani' },
    { label: 'Kenyan', value: 'Kenyan' },
    { label: 'Kittian and Nevisian', value: 'Kittian and Nevisian' },
    { label: 'Kuwaiti', value: 'Kuwaiti' },
    { label: 'Kyrgyz', value: 'Kyrgyz' },
    { label: 'Laotian', value: 'Laotian' },
    { label: 'Latvian', value: 'Latvian' },
    { label: 'Lebanese', value: 'Lebanese' },
    { label: 'Liberian', value: 'Liberian' },
    { label: 'Libyan', value: 'Libyan' },
    { label: 'Liechtensteiner', value: 'Liechtensteiner' },
    { label: 'Lithuanian', value: 'Lithuanian' },
    { label: 'Luxembourger', value: 'Luxembourger' },
    { label: 'Macedonian', value: 'Macedonian' },
    { label: 'Malagasy', value: 'Malagasy' },
    { label: 'Malawian', value: 'Malawian' },
    { label: 'Malaysian', value: 'Malaysian' },
    { label: 'Maldivan', value: 'Maldivan' },
    { label: 'Malian', value: 'Malian' },
    { label: 'Maltese', value: 'Maltese' },
    { label: 'Marshallese', value: 'Marshallese' },
    { label: 'Mauritanian', value: 'Mauritanian' },
    { label: 'Mauritian', value: 'Mauritian' },
    { label: 'Mexican', value: 'Mexican' },
    { label: 'Micronesian', value: 'Micronesian' },
    { label: 'Moldovan', value: 'Moldovan' },
    { label: 'Monacan', value: 'Monacan' },
    { label: 'Mongolian', value: 'Mongolian' },
    { label: 'Moroccan', value: 'Moroccan' },
    { label: 'Mosotho', value: 'Mosotho' },
    { label: 'Motswana', value: 'Motswana' },
    { label: 'Mozambican', value: 'Mozambican' },
    { label: 'Namibian', value: 'Namibian' },
    { label: 'Nauruan', value: 'Nauruan' },
    { label: 'Nepalese', value: 'Nepalese' },
    { label: 'New Zealander', value: 'New Zealander' },
    { label: 'Nicaraguan', value: 'Nicaraguan' },
    { label: 'Nigerian', value: 'Nigerian' },
    { label: 'Nigerien', value: 'Nigerien' },
    { label: 'North Korean', value: 'North Korean' },
    { label: 'Northern Irish', value: 'Northern Irish' },
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'Omani', value: 'Omani' },
    { label: 'Pakistani', value: 'Pakistani' },
    { label: 'Palauan', value: 'Palauan' },
    { label: 'Panamanian', value: 'Panamanian' },
    { label: 'Papua New Guinean', value: 'Papua New Guinean' },
    { label: 'Paraguayan', value: 'Paraguayan' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'Qatari', value: 'Qatari' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Rwandan', value: 'Rwandan' },
    { label: 'Saint Lucian', value: 'Saint Lucian' },
    { label: 'Salvadoran', value: 'Salvadoran' },
    { label: 'Samoan', value: 'Samoan' },
    { label: 'San Marinese', value: 'San Marinese' },
    { label: 'Sao Tomean', value: 'Sao Tomean' },
    { label: 'Saudi', value: 'Saudi' },
    { label: 'Scottish', value: 'Scottish' },
    { label: 'Senegalese', value: 'Senegalese' },
    { label: 'Serbian', value: 'Serbian' },
    { label: 'Seychellois', value: 'Seychellois' },
    { label: 'Sierra Leonean', value: 'Sierra Leonean' },
    { label: 'Singaporean', value: 'Singaporean' },
    { label: 'Slovakian', value: 'Slovakian' },
    { label: 'Slovenian', value: 'Slovenian' },
    { label: 'Solomon Islander', value: 'Solomon Islander' },
    { label: 'Somali', value: 'Somali' },
    { label: 'South African', value: 'South African' },
    { label: 'South Korean', value: 'South Korean' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Sri Lankan', value: 'Sri Lankan' },
    { label: 'Sudanese', value: 'Sudanese' },
    { label: 'Surinamer', value: 'Surinamer' },
    { label: 'Swazi', value: 'Swazi' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Swiss', value: 'Swiss' },
    { label: 'Syrian', value: 'Syrian' },
    { label: 'Taiwanese', value: 'Taiwanese' },
    { label: 'Tajik', value: 'Tajik' },
    { label: 'Tanzanian', value: 'Tanzanian' },
    { label: 'Thai', value: 'Thai' },
    { label: 'Togolese', value: 'Togolese' },
    { label: 'Tongan', value: 'Tongan' },
    { label: 'Trinidadian or Tobagonian', value: 'Trinidadian or Tobagonian' },
    { label: 'Tunisian', value: 'Tunisian' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Tuvaluan', value: 'Tuvaluan' },
    { label: 'Ugandan', value: 'Ugandan' },
    { label: 'Ukrainian', value: 'Ukrainian' },
    { label: 'Uruguayan', value: 'Uruguayan' },
    { label: 'Uzbekistani', value: 'Uzbekistani' },
    { label: 'Venezuelan', value: 'Venezuelan' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Welsh', value: 'Welsh' },
    { label: 'Yemenite', value: 'Yemenite' },
    { label: 'Zambian', value: 'Zambian' },
    { label: 'Zimbabwean', value: 'Zimbabwean' }
];

export const countryList = [
    { label: 'Afghanistan', value: 'Afghanistan' },
    { label: 'Albania', value: 'Albania' },
    { label: 'Algeria', value: 'Algeria' },
    { label: 'Andorra', value: 'Andorra' },
    { label: 'Angola', value: 'Angola' },
    { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { label: 'Argentina', value: 'Argentina' },
    { label: 'Armenia', value: 'Armenia' },
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Azerbaijan', value: 'Azerbaijan' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Bahrain', value: 'Bahrain' },
    { label: 'Bangladesh', value: 'Bangladesh' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Belize', value: 'Belize' },
    { label: 'Benin', value: 'Benin' },
    { label: 'Bhutan', value: 'Bhutan' },
    { label: 'Bolivia', value: 'Bolivia' },
    { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
    { label: 'Botswana', value: 'Botswana' },
    { label: 'Brazil', value: 'Brazil' },
    { label: 'Brunei', value: 'Brunei' },
    { label: 'Bulgaria', value: 'Bulgaria' },
    { label: 'Burkina Faso', value: 'Burkina Faso' },
    { label: 'Burundi', value: 'Burundi' },
    { label: 'Cabo Verde', value: 'Cabo Verde' },
    { label: 'Cambodia', value: 'Cambodia' },
    { label: 'Cameroon', value: 'Cameroon' },
    { label: 'Canada', value: 'Canada' },
    { label: 'Central African Republic', value: 'Central African Republic' },
    { label: 'Chad', value: 'Chad' },
    { label: 'Chile', value: 'Chile' },
    { label: 'China', value: 'China' },
    { label: 'Colombia', value: 'Colombia' },
    { label: 'Comoros', value: 'Comoros' },
    { label: 'Congo', value: 'Congo' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Côte d\'Ivoire', value: 'Côte d\'Ivoire' },
    { label: 'Croatia', value: 'Croatia' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Cyprus', value: 'Cyprus' },
    { label: 'Czech Republic', value: 'Czech Republic' },
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Djibouti', value: 'Djibouti' },
    { label: 'Dominica', value: 'Dominica' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'East Timor', value: 'East Timor' },
    { label: 'Ecuador', value: 'Ecuador' },
    { label: 'Egypt', value: 'Egypt' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { label: 'Eritrea', value: 'Eritrea' },
    { label: 'Estonia', value: 'Estonia' },
    { label: 'Eswatini', value: 'Eswatini' },
    { label: 'Ethiopia', value: 'Ethiopia' },
    { label: 'Fiji', value: 'Fiji' },
    { label: 'Finland', value: 'Finland' },
    { label: 'France', value: 'France' },
    { label: 'Gabon', value: 'Gabon' },
    { label: 'Gambia', value: 'Gambia' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Greece', value: 'Greece' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Guinea', value: 'Guinea' },
    { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { label: 'Guyana', value: 'Guyana' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Hungary', value: 'Hungary' },
    { label: 'Iceland', value: 'Iceland' },
    { label: 'India', value: 'India' },
    { label: 'Indonesia', value: 'Indonesia' },
    { label: 'Iran', value: 'Iran' },
    { label: 'Iraq', value: 'Iraq' },
    { label: 'Ireland', value: 'Ireland' },
    { label: 'Israel', value: 'Israel' },
    { label: 'Italy', value: 'Italy' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Jordan', value: 'Jordan' },
    { label: 'Kazakhstan', value: 'Kazakhstan' },
    { label: 'Kenya', value: 'Kenya' },
    { label: 'Kiribati', value: 'Kiribati' },
    { label: 'Korea, North', value: 'Korea, North' },
    { label: 'Korea, South', value: 'Korea, South' },
    { label: 'Kosovo', value: 'Kosovo' },
    { label: 'Kuwait', value: 'Kuwait' },
    { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { label: 'Laos', value: 'Laos' },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Lebanon', value: 'Lebanon' },
    { label: 'Lesotho', value: 'Lesotho' },
    { label: 'Liberia', value: 'Liberia' },
    { label: 'Libya', value: 'Libya' },
    { label: 'Liechtenstein', value: 'Liechtenstein' },
    { label: 'Lithuania', value: 'Lithuania' },
    { label: 'Luxembourg', value: 'Luxembourg' },
    { label: 'Madagascar', value: 'Madagascar' },
    { label: 'Malawi', value: 'Malawi' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'Maldives', value: 'Maldives' },
    { label: 'Mali', value: 'Mali' },
    { label: 'Malta', value: 'Malta' },
    { label: 'Marshall Islands', value: 'Marshall Islands' },
    { label: 'Mauritania', value: 'Mauritania' },
    { label: 'Mauritius', value: 'Mauritius' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Micronesia', value: 'Micronesia' },
    { label: 'Moldova', value: 'Moldova' },
    { label: 'Monaco', value: 'Monaco' },
    { label: 'Mongolia', value: 'Mongolia' },
    { label: 'Montenegro', value: 'Montenegro' },
    { label: 'Morocco', value: 'Morocco' },
    { label: 'Mozambique', value: 'Mozambique' },
    { label: 'Myanmar', value: 'Myanmar' },
    { label: 'Namibia', value: 'Namibia' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Nepal', value: 'Nepal' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Niger', value: 'Niger' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'North Macedonia', value: 'North Macedonia' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Oman', value: 'Oman' },
    { label: 'Pakistan', value: 'Pakistan' },
    { label: 'Palau', value: 'Palau' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Papua New Guinea', value: 'Papua New Guinea' },
    { label: 'Paraguay', value: 'Paraguay' },
    { label: 'Peru', value: 'Peru' },
    { label: 'Philippines', value: 'Philippines' },
    { label: 'Poland', value: 'Poland' },
    { label: 'Portugal', value: 'Portugal' },
    { label: 'Qatar', value: 'Qatar' },
    { label: 'Romania', value: 'Romania' },
    { label: 'Russia', value: 'Russia' },
    { label: 'Rwanda', value: 'Rwanda' },
    { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
    { label: 'Saint Lucia', value: 'Saint Lucia' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { label: 'Samoa', value: 'Samoa' },
    { label: 'San Marino', value: 'San Marino' },
    { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia' },
    { label: 'Senegal', value: 'Senegal' },
    { label: 'Serbia', value: 'Serbia' },
    { label: 'Seychelles', value: 'Seychelles' },
    { label: 'Sierra Leone', value: 'Sierra Leone' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'Slovakia', value: 'Slovakia' },
    { label: 'Slovenia', value: 'Slovenia' },
    { label: 'Solomon Islands', value: 'Solomon Islands' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'South Africa', value: 'South Africa' },
    { label: 'South Sudan', value: 'South Sudan' },
    { label: 'Spain', value: 'Spain' },
    { label: 'Sri Lanka', value: 'Sri Lanka' },
    { label: 'Sudan', value: 'Sudan' },
    { label: 'Suriname', value: 'Suriname' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Syria', value: 'Syria' },
    { label: 'Taiwan', value: 'Taiwan' },
    { label: 'Tajikistan', value: 'Tajikistan' },
    { label: 'Tanzania', value: 'Tanzania' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'Togo', value: 'Togo' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
    { label: 'Tunisia', value: 'Tunisia' },
    { label: 'Turkey', value: 'Turkey' },
    { label: 'Turkmenistan', value: 'Turkmenistan' },
    { label: 'Tuvalu', value: 'Tuvalu' },
    { label: 'Uganda', value: 'Uganda' },
    { label: 'Ukraine', value: 'Ukraine' },
    { label: 'United Arab Emirates', value: 'United Arab Emirates' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'United States', value: 'United States' },
    { label: 'Uruguay', value: 'Uruguay' },
    { label: 'Uzbekistan', value: 'Uzbekistan' },
    { label: 'Vanuatu', value: 'Vanuatu' },
    { label: 'Vatican City', value: 'Vatican City' },
    { label: 'Venezuela', value: 'Venezuela' }
]

export const transactionTypeList = [
    { label: 'Issue', value: 1 },
    { label: 'Return', value: 2 },
    { label: 'Lossed', value: 3 },
    { label: 'Damagged', value: 4 },
    { label: 'Recovered', value: 5 }
];

export const userTypeList = [
    { label: 'Employee', value: 'Employee' },
    { label: 'Student', value: 'Student' },
    { label: 'Others', value: 'Others' }
];

export const bookCategoryList = [
    { label: 'General', value: 1 },
    { label: 'Pharmacy', value: 2 },
    { label: 'Law', value: 3 },
];

export const bookTypeList = [
    { label: 'Reference', value: 'Reference' },
    { label: 'Textbook', value: 'Textbook' },
];

export const transactionType = [
    { label: 'Issue', value: 1 },
    { label: 'Return', value: 2 },
    { label: 'Lossed', value: 3 },
    { label: 'Damagged', value: 4 },
    { label: 'Recovered', value: 5 },
];

export const viewType = [
    { label: 'Semester ', value: 'Semester ' },
    { label: 'Annual', value: 'Annual' }
];

export const dataTypeList = [
    { label: 'String ', value: 'String ' },
    { label: 'Integer', value: 'Int' },
    { label: 'Boolean ', value: 'Boolean' },
    { label: 'Float', value: 'Float' },
    { label: 'Double', value: 'Double' },
    { label: 'Character', value: 'Char' },
    { label: 'DateTime', value: 'DateTime' },
    { label: 'Byte', value: 'Byte' }
];

export const departmentList = [
    { label: 'SmallBiz Gurus', value: 'SmallBizGurus' },
    { label: 'Finance Pro', value: 'FinancePro' },
    { label: 'Mind Spark', value: 'MindSpark' },
    { label: 'Knowledge Stand', value: 'KnowledgeStand' },
    { label: 'Students', value: 'Students' },
];

export const feeComponentGroupList = [
    { label: 'Semester', value: 'Semester' },
    { label: 'Other', value: 'Other' },
    { label: 'Other - Without Repetition', value: 'Other - Without Repetition' }
];

export const floorList = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
];

export const partnerAppSettingTypeList=[
    { label: 'String', value: 'String' },
    { label: 'Integer', value: 'Integer' },
    { label: 'Boolean', value: 'Boolean' },
    { label: 'Float', value: 'Float' },
    { label: 'Double', value: 'Double' },
    { label: 'Character', value: 'Character' },
    { label: 'DateTime', value: 'DateTime' },
    { label: 'Byte', value: 'Byte' },
    { label: 'JsonObject', value: 'JsonObject' },
    { label: 'StringArray', value: 'StringArray' },
    { label: 'IntegerArray', value: 'IntegerArray' },
]
